import { module } from 'modujs';
import { lazyLoadImage } from '../utils/image';
import LoconativeScroll from 'loconative-scroll';
import { html } from '../utils/environment';


export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        window.scrollValue = {
            x: 0,
            y: 0
        }
        window.scrollTo(0,0);

        setTimeout(() => {
            this.scroll = new LoconativeScroll({
                el: this.el,
                smooth: true,
                multiplier: 0.7,
                firefoxMultiplier: 60,
                getDirection: true
            });

            html.setAttribute('data-direction', 'down');

            this.scroll.on('call', (func, way, obj, id) => {
                // Using modularJS
                this.call(func[0], { way, obj }, func[1], func[2]);
            });

            this.scroll.on('scroll', (args) => {
                html.setAttribute('data-direction', args.direction);
                window.scrollValue.y = args.scroll.y;

                if(typeof args.currentElements['star'] === 'object') {
                    let progress = args.currentElements['star'].progress;
                    args.currentElements['star'].el.style.transform = `rotate(${progress * 360}deg)`;
                }
            });

        }, 800);

    }

    /**
     * Lazy load the related image.
     *
     * @see ../utils/image.js
     *
     * It is recommended to wrap your `<img>` into an element with the
     * CSS class name `.c-lazy`. The CSS class name modifier `.-lazy-loaded`
     * will be applied on both the image and the parent wrapper.
     *
     * ```html
     * <div class="c-lazy o-ratio u-4:3">
     *     <img data-scroll data-scroll-call="lazyLoad, Scroll, main" data-src="http://picsum.photos/640/480?v=1" alt="" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
     * </div>
     * ```
     *
     * @param {LocomotiveScroll} args - The Locomotive Scroll instance.
     */
    lazyLoad(args) {
        lazyLoadImage(args.obj.el, null, () => {
            //callback
        })
    }

    scrollTo(param) {
        if (this.scroll && this.scroll.scrollTo) this.scroll.scrollTo(param.target, param.options);
    }

    update() {
        this.scroll.update();
    }

    destroy() {
        this.scroll.destroy();
    }
}
