import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: 'click'
        }
    }

    init() {

    }

    click() {
        this.call('open','Nav');
    }
}
