import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        this.type = this.getData('type') ? this.getData('type') : 'lines';

        setTimeout(() => {
            this.split = new SplitText(this.el, { type: this.type })
            const lines = this.split.lines.slice();

            for (var i = 0; i < lines.length; i++) {
                lines[i] = new SplitText(lines[i], { type: this.type });
            }
            this.el.classList.add('is-split');
        },400);

    }
}
