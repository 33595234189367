import { module } from 'modujs';
import { html } from '../utils/environment';
import { gsap } from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                toggler: 'toggle'
            }
        }
    }

    init() {
    }

    /**
     * Toggler
     *
     * @param item {node}
     */
    toggle(e) {
        let el = e.curTarget;
        let parent = this.parent('item', el);

        if(parent.classList.contains('is-open')) {
            this.close();
        } else {
            this.close();
            this.open(parent);
        }
    }

    /**
     * open
     *
     * @param parentNode item {node}
     */
    open(item) {
        let collapsible = this.$('content', item)[0];
        item.classList.add('is-open');

        gsap.to(collapsible, {
            duration: 0,
            height: this.compute(this.$('inner', item)[0]).height,
            onComplete: () => {
                if (!html.classList.contains('has-scroll-smooth')) {
                    setTimeout(() => {
                        this.call('scrollTo', {target: item, options : {speed: 0.2, disableLerp: true}}, 'Scroll')
                        this.call('update', 'Scroll');
                    },600);

                } else {
                    setTimeout(() => {
                        this.call('scrollTo', {target: item, options : {speed: 0.2, disableLerp: true}}, 'Scroll')
                        this.call('update', 'Scroll');
                    },600);
                }
            }
        })
    }

    /**
     * Close
     *
     * @param
     */
    close() {
        this.$('item').forEach((item) => {
            item.classList.remove('is-open');

            let collapsible = this.$('content', item)[0];

            if(collapsible) {
                gsap.to(collapsible, 0, {
                    height: 0,
                    onComplete: () => {
                        setTimeout(() => {
                            this.call('update', 'Scroll');
                        },600);
                    }
                })
            }
        })
    }

    /**
     * compute
     *
     * @param
     */
    compute(item) {
        return item.getBoundingClientRect();
    }
}
