import { module } from 'modujs';
import modularLoad from 'modularload';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.analyticsId = this.getData('analytics');

        const load = new modularLoad({
            enterDelay: 1200,
            exitDelay: 0,
            transitions: {
                customTransition: {}
            }
        });

        this.colors = [
            '#EF765D',
            '#52A3CD',
            '#367552'
        ];

        this.$transitionBackground = document.getElementById('transition-background');
        this.$transition = document.getElementById('transition');
        this.updateTransition();

        load.on('loading', (transition, oldContainer) => {
            this.updateTransition();

            html.classList.remove('has-nav-open');
            html.classList.remove('has-ui-light');
            html.classList.remove('has-dom-ready');
        });

        load.on('loaded', (transition, oldContainer, newContainer) => {
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');

            html.classList.remove('has-dom-animated');

            // google tag manager
            if(window.gtag && this.analyticsId != null) {
                gtag('config', this.analyticsId, {
                    'page_path':  location.pathname,
                    'page_title': document.title
                });
            }

            setTimeout(() => {
                html.classList.add('has-dom-ready');

                setTimeout(() => {
                    html.classList.add('has-dom-animated');
                }, 2000);
            }, 450);
        });
    }

    updateTransition() {
        this.randomBackgroundColor = Math.floor(Math.random() * this.colors.length);
        this.$transitionBackground.style.backgroundColor = this.colors[this.randomBackgroundColor];

        if(this.randomBackgroundColor === 2) {
            this.$transition.style.color = '#ffffff'
        } else {
            this.$transition.style.color = '#232C5F';
        }
    }
}
