import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

    }

    trigger(e) {

        if(e.way === 'enter') {
            if(e.obj.el.getAttribute('data-theme') === 'light') {
                html.classList.add('has-ui-light');
            }
            if(e.obj.el.getAttribute('data-theme') === 'dark') {
                html.classList.remove('has-ui-light');
            }
        }
        // console.log(e.way, html.getAttribute('data-direction') === 'up')
        if(e.way === 'exit' && html.getAttribute('data-direction') === 'up') {
            if(e.obj.el.getAttribute('data-theme') === 'light') {
                html.classList.remove(`has-ui-light`);
            }
            if(e.obj.el.getAttribute('data-theme') === 'dark') {
                html.classList.add(`has-ui-light`);
            }
        }
    }
}
