import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.$canvas = this.$('canvas')[0];
    }

    init() {
        this.mouse = {
            x: 0,
            y: 0
        }
        this.width = this.el.getBoundingClientRect().width;
        this.height = this.el.getBoundingClientRect().height;
        this.scrollOffset = 0;

        this.$canvas.width = this.width;
        this.$canvas.height = this.height;
        this.context = this.$canvas.getContext('2d');
        this.context.strokeStyle = '#232C5F';
        this.context.lineWidth = 2;

        this.bindResize = this.resize.bind(this);
        window.addEventListener('resize', this.bindResize);


        this.points = [];
        this.maxLength = 50;
        this.hasMoved = false;

        this.bindMousemove = this.mousemove.bind(this);
        window.addEventListener('mousemove', this.bindMousemove);

        this.render();
    }

    resize() {
        this.width = this.el.getBoundingClientRect().width;
        this.height = this.el.getBoundingClientRect().height;

        this.$canvas.width = this.width;
        this.$canvas.height = this.height;
    }

    mousemove(e) {
        this.mouse.x = e.clientX;
        this.mouse.y = e.clientY;

        if(!this.hasMoved) this.hasMoved = true;

    }

    render() {
        this.raf = requestAnimationFrame(() => this.render());

        if(this.hasMoved) {
            this.scrollOffset = window.scrollValue.y;

            this.points.push({
                x: this.mouse.x,
                y: this.mouse.y + this.scrollOffset
            });

            if(this.points.length > this.maxLength) {
                this.points.shift();
            }

            if(this.points.length > 0) {
                this.context.beginPath();
                this.context.clearRect(0, 0, this.width, this.height);
                this.context.moveTo(this.points[0].x, this.points[0].y);

                this.points.forEach(point => {
                    this.context.lineTo(point.x, point.y)
                });
                this.context.stroke();
                this.context.closePath();
            }
        }
    }

    destroy() {
        window.removeEventListener('resize', this.bindResize);
        window.removeEventListener('mousemove', this.bindMousemove);
        cancelAnimationFrame(this.raf);
    }
}
