import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: 'trigger'
        }
    }

    init() {

    }

    trigger(e) {

        this.call('open',
            {
                provider: this.el.getAttribute('data-provider'),
                id: this.el.getAttribute('data-id'),
                iframe: this.el.getAttribute('data-iframe')
            },'Popup')
    }
}
