import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                close: 'close'
            }
        }
    }

    init() {
        this.colors = ['#EF765D', '#52A3CD'];
        this.$background = this.$('background')[0];

        this.closeBind = (e) => {
            if (e.key === "Escape") {
               this.close();
           }
       }

       document.addEventListener('keyup', this.closeBind)
    }

    open() {
        html.classList.add('has-nav-open');
        this.$background.style.backgroundColor = this.colors[Math.floor(Math.random() * this.colors.length)];
    }

    close() {
        html.classList.remove('has-nav-open');

    }

    destroy() {
        document.removeEventListener('keyup', this.closeBind)
    }
}
