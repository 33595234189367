import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                closeButton: 'close'
            }
        }
    }

    init() {
        this.$inner = this.$('inner')[0];

        this.closeBind = (e) => {
             if (e.key === "Escape") {
                this.close();
            }
        }

        document.addEventListener('keyup', this.closeBind)
    }

    open(param) {
        if(this.timeout !== undefined) clearTimeout(this.timeout);

        console.log(param.iframe);

        if(param.iframe !== '') {
            this.$inner.innerHTML = param.iframe;
        } else {
            if(param.provider === 'vimeo') {
                this.$inner.innerHTML = `<iframe src="https://player.vimeo.com/video/${param.id}?controls=true&amp;autoplay=1&amp;transparent=false&amp;autopause=false&amp;muted=0"frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" allow="autoplay; encrypted-media"></iframe>`;
            } else if (param.provider = 'youtube') {
                this.$inner.innerHTML = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${param.id}?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

            }
        }

        html.classList.add('has-popup-open');
    }

    close() {
        html.classList.add('has-popup-closing');
        html.classList.remove('has-popup-open');

        this.timeout = setTimeout(() => {
            this.$inner.innerHTML = '';
        },600);

        setTimeout(() => {
            html.classList.remove('has-popup-closing');
        },600);
    }

    destroy() {
        document.removeEventListener('keyup', this.closeBind)
    }
}
