import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        if(Math.random() < 0.5) {
            this.el.classList.add('is-blue');
            html.classList.add('has-ui-light');
        }
    }
}
