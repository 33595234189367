import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.oldEl = null;
        this.timeout;

        this.$counter = this.$('count')[0];
        this.$length = this.$('length')[0];
        this.$items = this.$('item');

        this.$length.innerText = this.$items.length;
    }

    trigger(e) {
        if(e.way === 'enter') {
            let idTarget = e.obj.el.getAttribute('data-target')
            this.$items.forEach(element => {
                if(element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                    element.classList.add('is-leaving');

                    this.timeout = setTimeout(() => {
                        element.classList.remove('is-leaving');
                    }, 600)
                }
            if(element.getAttribute('data-slide') === idTarget) {
                    element.classList.remove('is-leaving');
                    element.classList.add('is-active');
                }
            });

            this.$counter.innerText = idTarget;

            if(idTarget == this.$items.length) {
                this.el.classList.add('has-latest-slide');
            } else {
                this.el.classList.remove('has-latest-slide');
            }

        }
    }
}
